// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// routes
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router';
// components
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function AddPopover() {

  const popover = usePopover();
  const navigate = useNavigate();

  const handleClick = (value) => {
    navigate(value);
    popover.onClose();
  };

  return (
    <>
      <IconButton
        size='small'
        onClick={popover.onOpen}
      >
        <Iconify sx={{ backgroundColor: 'default' }} icon='ic:round-add' width={27} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <MenuItem
          onClick={() => handleClick(paths.dashboard.crm.contact.new)}
          sx={{ pl: 2 }}
        >
          New Contact
        </MenuItem>
        <MenuItem
          onClick={() => handleClick(paths.dashboard.crm.company.new)}
          sx={{ pl: 2 }}
        >
          New Company
        </MenuItem>
        <MenuItem
          onClick={() => handleClick(paths.dashboard.crm.lead.new)}
          sx={{ pl: 2 }}
        >
          New Lead
        </MenuItem>
        <MenuItem
          onClick={() => handleClick(paths.dashboard.crm.opportunity.new)}
          sx={{ pl: 2 }}
        >
          New Opportunity
        </MenuItem>
      </CustomPopover>
    </>
  );
}
