import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
// stripe
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useLazyStripeSetCustomerDefaultPaymentMethodQuery, useStripeSetupIntentQuery } from 'src/redux/services/salesrush/endpoints';
import { useDispatch, useSelector } from 'src/redux/store';
import { enqueueSnackbar } from 'notistack';
// components
import { useParams } from 'src/routes/hook';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { setUpdateCustomer, setUpdateListPaymenMethod, setUpdatePayment } from 'src/redux/slices/user';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE } from 'src/config-global';
import { Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { endpoints, postFetcher } from 'src/utils/axios';
import { useReadUserSub } from 'src/api/users';

// ----------------------------------------------------------------------

const stripePromise = loadStripe(STRIPE.client_secret)

export default function PaymentNewCardDialog({ onClose, isDefault = true, onChange_data , ...other }) {

  
  const { user } = useSelector(state => state.user);
    
  const popover = usePopover();
  const theme = useTheme();

  const params = useParams();

  const { id } = params;
  const { data: alldata } = useReadUserSub(id === undefined ? user.id : id);

  const customer = useMemo(() => alldata?.customer || {}, [alldata]);

  const { data, isLoading } = useStripeSetupIntentQuery({ customer: customer.id }, { refetchOnMountOrArgChange: true }); 

  return (
    <>
      <Dialog maxWidth="sm" onClose={onClose} {...other}>
        <DialogTitle> New Card </DialogTitle>
        
        <DialogContent>
          <Stack id="stack" spacing={2.5} sx={{ width: { sx: 'auto', md: 500 }, pt: 1 }}>
            {
              isLoading || !data  ? (
                <>Loading...</>
              ):(
                <Elements
                  stripe={stripePromise} options={{ 
                  clientSecret: data?.setupIntent?.client_secret,
                  loader: 'auto',
                  appearance: {
                    theme: theme.palette.mode === 'dark' ? 'night' : 'flat',
                    labels: 'floating'
                  }
                  }}
                >
                  <FormPayment onClose={onClose} customer={customer} isDefault={isDefault} onChange_data={onChange_data}/>
                </Elements>
              )
            }
          </Stack>
        </DialogContent>

        
      </Dialog>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="bottom-center"
        sx={{ maxWidth: 200, typography: 'body2', textAlign: 'center' }}
      >
        Three-digit number on the back of your VISA card
      </CustomPopover>
    </>
  );
}

function FormPayment({ onClose, customer, isDefault, onChange_data }) {
  console.log(customer, 'customer');
  const stripe = useStripe();
  const elements = useElements();

  
  const dispatch = useDispatch();


  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [setDefaultPayment, { isLoading }] = useLazyStripeSetCustomerDefaultPaymentMethodQuery();
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      setLoading(false);
      return;      
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        return_url: 'https://example.com',
      },
      redirect: 'if_required'
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setLoading(false);
      setErrorMessage(error.message);
    } else {
      const { payment_method } = setupIntent;

      if(!isDefault){
        const { data } = await postFetcher(endpoints.stripe.payment_method.list, { arg: { customer: customer.id } });
        dispatch(setUpdateListPaymenMethod({ list_payment_methods: data.data }));
        enqueueSnackbar('Payment Method Updated', { variant: 'success' });
        onChange_data();
        onClose();
        return;
      }
      try {
        
        const { customer: customerUpdated, payment_method: payment_method_updated, list_payment_methods } =  await setDefaultPayment({ customer_id: customer.id, payment_method_id: payment_method }).unwrap();
        enqueueSnackbar('Payment Method Updated', { variant: 'success' });
        dispatch(setUpdateCustomer({ customer: customerUpdated }));
        dispatch(setUpdatePayment({ payment_method: payment_method_updated }));
        dispatch(setUpdateListPaymenMethod({ list_payment_methods }));
        onChange_data();
        onClose();
      } catch (error_cd) {
        onChange_data();
        enqueueSnackbar('Network Error', { variant: 'error' });
      }
      setLoading(false);
    }

  } 
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && <Typography>{errorMessage}</Typography>}
      <DialogActions sx={{pl: 0, pr: 0}}>
        <Button color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton type='submit' disabled={loading} loading={loading} variant='contained'>
          Add Card
        </LoadingButton>
      </DialogActions>
    </form>
  )
}

FormPayment.propTypes = {
  onClose: PropTypes.func,
  customer: PropTypes.object,
  isDefault: PropTypes.bool,
  onChange_data: PropTypes.func,
};

PaymentNewCardDialog.propTypes = {
  onClose: PropTypes.func,
  isDefault: PropTypes.bool,
  onChange_data: PropTypes.func,
};
