import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { badgeClasses } from '@mui/material/Badge';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useBoolean } from 'src/hooks/use-boolean';
import PhoneNumberDial from 'src/sections/contact-center/phone-number-dial';
// twilio provider
import { useTwilio } from 'src/sections/contact-center/twilio-provider';
//
import SnackbarCallAlert from 'src/sections/contact-center/component/snackbar-alert';
// ----------------------------------------------------------------------

export default function PhoneButton({ sx }) {

  const { phoneNumberCalling, isReceivingCall, acceptCall, rejectCall, openCompose } = useTwilio();

  const handleAnswerCall = async () => {
    openCompose.onTrue();
    acceptCall();
  }

  return (
    <>
      <Box
        sx={{
          [`& .${badgeClasses.badge}`]: {
            top: 8,
            right: 8,
          },
          ...sx,
        }}
      >
        <Box>
          <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            aria-label="settings"
            onClick={openCompose.onTrue}
            sx={{
              width: 40,
              height: 40,
            }}
          >
            <Iconify icon="solar:phone-bold-duotone" width={24} />
          </IconButton>
        </Box>
        {openCompose.value && <PhoneNumberDial onCloseCompose={openCompose.onFalse} />}
      </Box>
      <SnackbarCallAlert
        isOpen={isReceivingCall}
        phonenumber={phoneNumberCalling}
        type='incoming'
        onAnswer={handleAnswerCall}
        onHangUp={rejectCall}

      />
    </>
  );
}

PhoneButton.propTypes = {
  sx: PropTypes.object,
};
