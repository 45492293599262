import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------


const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const icon_iconify = (name, size = 24) => (
  <Iconify icon={name} sx={{ fontSize: 24, width: 40 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  crm: icon('ic_user'),
  landing: icon('ic_landing'),
  external: icon('ic_external'),
  dashboard: icon('ic_dashboard'),
  editor: icon_iconify('solar:clapperboard-edit-bold'),
  billing: icon_iconify('solar:bill-check-bold'),
  reviews: icon_iconify('solar:star-circle-bold-duotone'),
  invoice: icon('ic_invoice'),
  label: icon('ic_label'),
  kanban: icon('ic_kanban'),
  setup: icon('ic_setup'),
  users: icon('ic_users'),
  call: icon_iconify('solar:volume-bold-duotone'),

};

// ----------------------------------------------------------------------

export function useNavData(subdomain) {
  const data = useMemo(
    () => [
      // Dashboard
      // ----------------------------------------------------------------------
      {
        subheader: 'Dashboard',
        items: [
          { title: 'Home', path: paths.dashboard.root, icon: ICONS.dashboard },
          {
            title: 'CRM',
            path: paths.dashboard.crm.root,
            icon: ICONS.crm,
            children: [
              { title: 'Contacts', path: paths.dashboard.crm.contact.list },
              { title: 'Companies', path: paths.dashboard.crm.company.list },
              { title: 'Leads', path: paths.dashboard.crm.lead.list },
              { title: 'Opportunities', path: paths.dashboard.crm.opportunity.list },
              { title: 'System Fields', path: paths.dashboard.crm.settings.list },
              { title: 'Pipelines', path: paths.dashboard.crm.pipelines.list },
            ],
          },
          {
            title: 'contact center',
            path: paths.dashboard.contact_center.root,
            icon: ICONS.call,
            children: [
              { title: 'call logs', path: paths.dashboard.contact_center.calls },
              // { title: 'messages logs', path: paths.dashboard.contact_center.messages },
              { title: 'phone numbers', path: paths.dashboard.contact_center.phone_numbers },
              // { title: 'a2p', path: paths.dashboard.contact_center.a2p },
              // { title: 'usage', path: paths.dashboard.contact_center.usage },
            ],
          },
          {
            title: 'users',
            path: paths.dashboard.users.root,
            icon: ICONS.users,
            children: [
              { title: 'List', path: paths.dashboard.users.users.list },
              { title: 'Create', path: paths.dashboard.users.users.new },
              { title: 'Teams', path: paths.dashboard.users.teams.list },
              { title: 'Permission Sets', path: paths.dashboard.users.permissions.list },
            ],
          },
        ],
      },
      // WEBFINDER
      // ----------------------------------------------------------------------
      {
        subheader: 'WEBFINDER',
        items: [
          {
            title: 'Landing Pages',
            path: paths.dashboard.landing.root,
            icon: ICONS.landing,
            children: [
              { title: 'List', path: paths.dashboard.landing.list },
              { title: 'Create', path: paths.dashboard.landing.new },
            ],
          },
          {
            title: 'blog',
            path: paths.dashboard.post.root,
            icon: ICONS.blog,
            children: [
              { title: 'list', path: paths.dashboard.post.root },
              { title: 'create', path: paths.dashboard.post.new },
            ],
          },
          {
            title: 'reviews',
            path: paths.dashboard.review.root,
            icon: ICONS.label,
            children: [
              { title: 'list', path: paths.dashboard.review.root },
              { title: 'create', path: paths.dashboard.review.new },
            ],
          },
          { title: 'gallery', path: paths.dashboard.product.new, icon: ICONS.kanban },
          { title: 'preview', path: `${process.env.REACT_APP_WEBFINDER_PROTOCOL}://${subdomain}.${process.env.REACT_APP_WEBFINDER}/`, icon: ICONS.external },
        ],
      },
    ],
    [subdomain]
  );

  return data;
}
