import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useDispatch, useSelector } from 'src/redux/store';
import { AUTH } from 'src/firebase';
import { logOutUser } from 'src/redux/slices/user';
import { clearContacts } from 'src/redux/slices/contacts';
import { clearContact_note } from 'src/redux/slices/contact_notes';
import { clearPost } from 'src/redux/slices/posts';
import { clearReviews } from 'src/redux/slices/reviews';
import { clearSubscriptions } from 'src/redux/slices/subscriptions';
import { clearGallery } from 'src/redux/slices/gallery';
// Stores
import {
  useContactSearchStore,
  useCompanySearchStore,
  useLeadSearchStore,
  useOpportunitySearchStore,
} from 'src/stores';

// ----------------------------------------------------------------------

export default function AccountPopover(prompts = false) {

  const { user } = useSelector(state => state.user);

  const OPTIONS = [
    {
      label: 'Home',
      linkTo: '/dashboard',
    },
    {
      label: 'Profile',
      linkTo: paths.dashboard.users.users.edit(user.id),
    },
    {
      label: 'Support',
      linkTo: 'https://calendly.com/salesrushai/sales-rush-support',
    },
  ];

  const router = useRouter();
  // Stores
  const clearContactState = useContactSearchStore((state) => state.clearState);
  const clearCompanyState = useCompanySearchStore((state) => state.clearState);
  const clearLeadState = useLeadSearchStore((state) => state.clearState);
  const clearOpportunityState = useOpportunitySearchStore((state) => state.clearState);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      AUTH.signOut();
      dispatch(logOutUser());
      dispatch(clearContacts());
      dispatch(clearContact_note());
      dispatch(clearPost());
      dispatch(clearReviews());
      dispatch(clearGallery());
      dispatch(clearSubscriptions());
      clearContactState();
      clearCompanyState();
      clearLeadState();
      clearOpportunityState();
      popover.onClose();
      router.replace('/auth/login');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {

    if (path.indexOf('http') > -1) {
      window.open(path, '_blank')
      return
    }

    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.avatar_url || 'https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_25.jpg'}
          alt={`${user?.firstname} ${user?.lastname}`}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {/* {user.user_level === "broker" ? user.company_name : `${user?.firstname} ${user?.lastname}`} */}
            {`${user?.firstname} ${user?.lastname}`}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {/* {user.user_level === "broker" ? 'Broker Edition' : 'Realtor Edition'} */}
            {user?.company_name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {!prompts.reactive && (
          <>
            <Stack sx={{ p: 1 }}>
              {OPTIONS.map((option) => (
                <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                  {option.label}
                </MenuItem>
              ))}
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        )}
        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
