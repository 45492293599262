// Check if user has permission to view
// user = object user, type = string, unassigned = boolean, owner_id = string
export const permissionsToRead = (user, type, unassigned, owner_id) => {
  let showItem = true;
  if(type === 'NONE') {
    showItem = false;
  }
  if(type === 'ALL') {
    if(!unassigned && !owner_id) {
      showItem = false;
    }
  }
  if(type === 'THEY OWN') {
    if(owner_id !== user.id) {
      showItem = false;
    }
    if(unassigned && !owner_id) {
      showItem = true;
    }
  }
  return showItem;
};

// Check if user has permission to update
// user = object user, type = string, unassigned = boolean, owner_id = string
export const permissionsToUpdate = (user, type, unassigned, owner_id) => {
  let canUpdate = true;
  if(type === false) {
    canUpdate = false;
  }
  if(type === 'NONE') {
    canUpdate = false;
  }
  if(type === 'ALL') {
    if(!unassigned && !owner_id) {
      canUpdate = false;
    }
  }
  if(type === 'THEY OWN') {
    if(owner_id !== user.id) {
      canUpdate = false;
    }
    if(unassigned && !owner_id) {
      canUpdate = true;
    }
  }
  return canUpdate;
};

// Check if user has permission to delete
// user = object user, type = string, unassigned = boolean, owner_id = string
export const permissionsToDelete = (user, type, unassigned, owner_id) => {
  let canDelete = true;
  if(type === false) {
    canDelete = false;
  }
  if(type === 'NONE') {
    canDelete = false;
  }
  if(type === 'ALL') {
    if(!unassigned && !owner_id) {
      canDelete = false;
    }
  }
  if(type === 'THEY OWN') {
    if(owner_id !== user.id) {
      canDelete = false;
    }
    if(unassigned && !owner_id) {
      canDelete = true;
    }
  }
  return canDelete;
};
