import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
    confirmationResult: null,
    isAuth: false,
    actionType: 'login',
    subscription: null, 
    invoices: null, 
    customer: null, 
    payment_method: null,
    list_payment_methods: null,
    user: {
        cell_phone_number: '',
        stripe_billing_status:'trialing',
        company_name: '',
        email: '',
        firstname: '',
        id: '',
        lastname: '',
        license_number:'',
        mailing_address: '',
        office_number:'',
        user_level: '',
        subdomain: '',
        crm_record_tags: [],
        users: [],
        last_active: '',
        account_owner: '',
        invite_status: '',
        owner_id: '',
        secondary_customer_id: '',
        twilio_sid: '',
        organization_id: '',
    },
    applied_permissions: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
        const { user, isAuth, actionType, subscription, invoices, customer, payment_method, list_payment_methods, applied_permissions } = action.payload;
        state.user = { ...state.user, ...user };
        state.isAuth = isAuth;
        state.actionType = actionType;
        state.customer = customer;
        state.subscription = subscription;
        state.invoices = invoices;
        state.payment_method = payment_method;
        state.list_payment_methods = list_payment_methods;
        state.applied_permissions = applied_permissions;
    },
    setUpdateUser(state, action) {
        const { user } = action.payload;
        state.user = user;
    },
    setUpdateUserElement(state, action) {
        const { key, value } = action.payload;
        state.user[key] = value;
    },
    setUpdateListPaymenMethod(state, action) {
        const { list_payment_methods } = action.payload;
        state.list_payment_methods = list_payment_methods;
    },
    logOutUser(state) {
        const { isAuth, user } = initialState;
        state.user = user;
        state.isAuth = isAuth;
    },
    setUpdateCustomer(state, action) {
        const { customer } = action.payload;
        state.customer = customer;
    },
    setUpdatePayment(state, action) {
        const { payment_method } = action.payload;
        state.payment_method = payment_method;
    },
    setUpdateSubscription(state, action) {
        const { subscription } = action.payload;
        state.subscription = subscription;
    },
    clearUser(state) {
        state.user = initialState.user;
        state.actionType = initialState.actionType;
    },
    setRemovePaymentMethod(state, action) {
        const { payment_method_id } = action.payload;
        state.list_payment_methods = [...filter(state.list_payment_methods, (payment_method)=>  payment_method.id !== payment_method_id )];
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setUser,
    setUpdateUser,
    setUpdateUserElement,
    setUpdateCustomer,
    setUpdatePayment,
    logOutUser,
    clearUser,
    setUpdateListPaymenMethod,
    setUpdateSubscription,
    setRemovePaymentMethod
} = slice.actions;
