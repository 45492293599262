import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Box, Typography, Button, Stack, Chip, Divider } from "@mui/material";
import Iconify from 'src/components/iconify';
import moment from "moment";
import AudioPlayer from "src/components/audio-player/audio-player";
import { endpoints, postFetcher } from "src/utils/axios";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "src/redux/store";
import { useBoolean } from "src/hooks/use-boolean";
import { ConfirmDialog } from "src/components/custom-dialog";
import { useTwilio } from "./twilio-provider";


export default function PhoneNumberDetailsRecents({ selectedCallInfo, setActiveSection, makeCall, setPhoneNumber }) {

    const user = useSelector((state) => state.user);
    const userTwilioSid = user.user.twilio_sid;
    const { userTwilioPhone } = useTwilio();
    const [selectRecordingUrl, setSelectRecordingUrl] = useState("")
    const confirm = useBoolean();
    // const selectRecordingUrl = "https://api.twilio.com/2010-04-01/Accounts/AC90af363c7e714581da7563760278e03d/Recordings/RE598b5d70a21b67814f901743ee36383d"
    useEffect(() => {
        const recordingURL = async (callLogItem) => {
            if (!callLogItem.recordingurl) {
                try {
                    const { data } = await postFetcher(endpoints.twilio.conference.recording.callSid, {
                        arg: {
                            CallSid: callLogItem.sid,
                            AccountSid: userTwilioSid
                        }
                    });
                    if (data) {
                        setSelectRecordingUrl(data.recordingUrl)
                    }

                } catch (error) {
                    console.error("Error fetching recording:", error);
                }
            } else {
                setSelectRecordingUrl(callLogItem.recordingurl)
            }
        };
        recordingURL(selectedCallInfo)
    }, [selectedCallInfo, userTwilioSid])

    const handleRemoveVoicemail = async () => {
        try {
            const { status } = await postFetcher(endpoints.twilio.conference.recording.removeVoicemail, {
                arg: {
                    userTwilioPhone,
                    userTwilioSid,
                    voicemailId: selectedCallInfo.id
                }
            });
            if (status === "success") {
                enqueueSnackbar('Delete Success!', { variant: 'success' })
                const activeSection = selectedCallInfo?.recordingurl ? 2 : 0
                setActiveSection(activeSection)
            } else {
                enqueueSnackbar('Error Delete', { variant: 'error' });
            }
            confirm.onFalse()

        } catch (error) {
            console.error("Error fetching recording:", error);
        }
    }

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: { xs: '75vh', sm: '55vh' },
                }}
            >
                {/* Encabezado */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">Call Details</Typography>
                    <Button
                        onClick={() => {
                            const activeSection = selectedCallInfo?.recordingurl ? 2 : 0
                            setActiveSection(activeSection)
                        }}
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        Back
                    </Button>
                </Box>

                {/* Información de la llamada */}
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    flexWrap="wrap" // Permite que los elementos se ajusten en filas.
                    sx={{
                        gap: 1, // Espaciado entre los elementos.
                        justifyContent: "space-between", // Ajusta los elementos en el espacio disponible.
                        paddingBottom: 2
                    }}
                >
                    <Chip
                        variant="outlined"
                        label={
                            <span>
                                <strong>From:</strong> {selectedCallInfo?.direction === 'inbound' ? selectedCallInfo?.from : selectedCallInfo?.to}
                            </span>
                        }
                        sx={{
                            flexGrow: 1, // Permite que el chip crezca si es necesario.
                            maxWidth: "100%", // Evita que el chip se desborde.
                        }}
                    />
                    <Chip
                        variant="outlined"
                        label={
                            <span>
                                <strong>Date:</strong> {moment(selectedCallInfo?.dateCreated).format('LL')}{" "}
                                {moment(selectedCallInfo?.dateCreated).format('h:mm:ss a')}
                            </span>
                        }
                        sx={{
                            flexGrow: 1,
                            maxWidth: "100%",
                        }}
                    />
                    <Chip
                        label={
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <Iconify icon="si:phone-fill" width="1.2rem" height="1.2rem" />
                                <span style={{ marginLeft: "0.5rem" }}>Call</span>
                            </span>
                        }
                        onClick={async () => {
                            const phone = selectedCallInfo?.direction === 'inbound' ? selectedCallInfo?.from : selectedCallInfo?.to
                            setPhoneNumber(phone)
                            await makeCall(phone)
                            setActiveSection(1)
                        }}
                        sx={{
                            flexGrow: 1,
                        }}
                    />
                </Stack>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {/* Grabación */}
                <Box
                    sx={{
                        height: "5px"
                    }}
                >
                    <Stack direction="row" spacing={1} flexGrow={1} sx={{ alignItems: 'center', marginBottom: 2 }}>
                        <Typography variant='subtitle2' sx={{ marginY: 'auto' }}>Play Recording</Typography>
                    </Stack>
                    <AudioPlayer urlRecording={selectRecordingUrl} />
                    {selectedCallInfo.recordingurl && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            flexWrap="wrap" // Permite que los elementos se ajusten en filas.
                            sx={{
                                justifyContent: "flex-end", // Ajusta los elementos en el espacio disponible.
                                paddingTop: 8
                            }}
                        >
                            <Chip
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <Iconify icon="material-symbols:delete-outline" width="1.2rem" height="1.2rem" />
                                        <span style={{ marginLeft: '0.5rem' }}>Delete Message</span>
                                    </span>
                                }
                                sx={{
                                    flexGrow: 1,
                                    maxWidth: "100%",
                                }}
                                color="error" // Esto le da un color rojo al Chip
                                onClick={(event) => {
                                    confirm.onTrue();
                                }}
                            />
                        </Stack>
                    )}
                </Box>

            </Box>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                titleCancel="Back"
                content="Are you sure you want to delete?"
                action={
                    <Button variant="contained" color="error" onClick={handleRemoveVoicemail}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
};

PhoneNumberDetailsRecents.propTypes = {
    selectedCallInfo: PropTypes.object,
    setActiveSection: PropTypes.func,
    makeCall: PropTypes.func,
    setPhoneNumber: PropTypes.func
};