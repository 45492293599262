import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

// ----------------------------------------------------------------------

export default function LandingTableRow({ row, selected, onEditRow, onSelectRow, handleActivateDeactivateRow }) {

  const { page_name, url_path, save_submission_to, created_by, status, display_on_site } = row;

  const confirm = useBoolean();

  return (
    <>
      <TableRow hover selected={selected}>

        <TableCell padding='checkbox'>

          <Checkbox checked={selected} onClick={onSelectRow} />

        </TableCell>

        <TableCell>

          <Typography
            component='span'
            color='inherit'
            variant='subtitle2'
            noWrap
            sx={{ textTransform: 'capitalize' }}
            onClick={() => onEditRow()}
          >
            {page_name}
          </Typography>

        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{`/${url_path}`}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{display_on_site ? 'Yes' : 'No'}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>{save_submission_to}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{created_by}</TableCell>

        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>

          <Tooltip title='View' placement='top' arrow>

            <IconButton color='default' onClick={() => onEditRow()}>
              <Iconify icon='solar:eye-bold' />
            </IconButton>

          </Tooltip>

          <Tooltip title={status === 'active' ? 'Deactivate' : 'Activate'} placement='top' arrow>

            <IconButton
              color={status === 'active' ? 'error' : 'success'}
              sx={{ color: created_by === 'Sales Rush' ? grey[400] : '' }}
              onClick={() => {
                if(created_by !== 'Sales Rush') {
                  confirm.onTrue();
                }
              }}
            >
              <Iconify icon={status === 'active' ? 'solar:trash-bin-trash-bold' : 'solar:shield-check-bold'} />
            </IconButton>

          </Tooltip>

        </TableCell>

      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={status === 'active' ? 'Deactivate' : 'Activate'}
        content={`Are you sure want to ${status === 'active' ? 'Deactivate' : 'Activate'}?`}
        action={
          <Button variant='contained' color={status === 'active' ? 'error' : 'success'} onClick={() => {
            handleActivateDeactivateRow()
            confirm.onFalse()
          }}>
            {status === 'active' ? 'Deactivate' : 'Activate'}
          </Button>
        }
      />
    </>
  );
}

LandingTableRow.propTypes = {
  handleActivateDeactivateRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
