// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function AlertDialog({ title, open, onClose, children, actions }) {
  const dialog = useBoolean();

return (
    <>
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                {actions.map((action, index) => (
                    <LoadingButton
                        key={index}
                        variant={action.variant || 'text'}
                        onClick={action.onClick || onClose}
                        autoFocus={action.autoFocus || false}
                        color={action.color || 'primary'}
                        loading={action.loading || false}
                    >
                        {action.label}
                    </LoadingButton>
                ))}
            </DialogActions>
        </Dialog>
    </>
);
}

AlertDialog.defaultProps = {
    title: 'Title',
    open: false,
    onClose: () => {},
    children: null,
    actions: [],
};

AlertDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
    actions: PropTypes.array,
};
