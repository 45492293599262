import PropTypes from 'prop-types';
import { Box, Card, CardContent, Fab, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'src/redux/store';
import axios from 'axios';
import { endpoints, postFetcher } from 'src/utils/axios';

const message = {
    incoming: 'Incoming call',
}

export default function SnackbarCallAlert({ phonenumber, type, onAnswer, onHangUp, isOpen }) {

    const { user } = useSelector((state) => state.user);
    const [contact, setContact] = useState(phonenumber);

    const getContact = useCallback(async(phone_number) => {
        if(!phone_number) {
            return;
        }

        const { contacts } = await postFetcher(endpoints.twilio.get_contact,
            {
              arg: {
                organizationId: user.organization_id, 
                phoneNumber: phone_number
              }
        });

        setContact(contacts || phonenumber);

    }, [phonenumber, user.organization_id]);

    useEffect(() => {
        if(!phonenumber) {
            return;
        }

        getContact(phonenumber);

    }, [phonenumber, getContact]);
        

    return (
<Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
    }}
    sx={{ my: 2 }}
    open={isOpen}
    autoHideDuration={6000}
>
    <Card style={{ display: 'flex', padding: '8px 10px', margin: 0, width: 350, height: 100 }}>
        {/* Envuelve el CardContent en un Box para centrarlo completamente */}
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center', // Centra horizontalmente
                alignItems: 'center', // Centra verticalmente
                width: 350,
            }}
        >
            <CardContent style={{ textAlign: 'left', padding: 0, width: 200, overflow: 'hidden',height: 120, display: 'grid' }}>
                <Typography 
                variant="subtitle2" 
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 200,
                    lineClamp: 2,
                    marginTop: 2,
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                    height: '45px',
                    textTransform: 'capitalize'
                }}>
                    {contact}
                </Typography>
                <Typography variant="subtitle2" color="primary">
                    {message[type]}
                </Typography>
            </CardContent>
        </Box>

        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={1}
        >
            <Grid item>
                <Fab
                    onClick={onAnswer}
                    color="success"
                    sx={{
                        width: 30,
                        height: 30,
                        minHeight: 'unset',
                    }}
                >
                    <CallIcon fontSize="small" />
                </Fab>
            </Grid>
            <Grid item>
                <Fab
                    onClick={onHangUp}
                    color="error"
                    sx={{
                        width: 30,
                        height: 30,
                        minHeight: 'unset',
                    }}
                >
                    <CallEndIcon fontSize="small" />
                </Fab>
            </Grid>
        </Grid>
    </Card>
</Snackbar>

    );
}

SnackbarCallAlert.propTypes = {
    phonenumber: PropTypes.string,
    type: PropTypes.string,
    onAnswer: PropTypes.func,
    onHangUp: PropTypes.func,
    isOpen: PropTypes.bool,
};

