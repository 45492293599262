import { useState } from 'react';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Box, CardActions, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
// hooks
import { useNavigate } from 'react-router';
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
// api
import { usePostStripeSubscriptionCancel, usePostStripeSubscriptionCreate } from 'src/api/stripe';
import { usePostStorePhoneNumberSubscription } from 'src/api/user';
// routes
import { enqueueSnackbar } from 'notistack';
// _mock
import Iconify from 'src/components/iconify';
import { useSelector } from 'react-redux';
import { usePostIncomingPhoneNumbers } from 'src/api/twilio';
import ContactCenterAlert from './contact-center-alert';
import PaymentMethods from '../payment/payment-methods';

// ----------------------------------------------------------------------

export default function PhoneNumberPurchase() {

  const mdUp = useResponsive('up', 'md');

  const [error, setError] = useState(null);
  const {  customer, user,list_payment_methods } = useSelector(state => state.user);
  

  const userTwilioSid = user?.twilio_sid;
  const [ card, setCard ] = useState(list_payment_methods.length > 0 ? list_payment_methods[0].id : null);

  const dialog = useBoolean();

  const navigate = useNavigate();

  const { price, friendlyName, phoneNumber } = useSelector(state => state.twilioAvailablePhoneNumber);
  const { subscriptionTrigger, subscriptionLoading } = usePostStripeSubscriptionCreate();
  const { subscriptionTrigger: subscriptionCancelTrigger } = usePostStripeSubscriptionCancel();
  const { incomingPhoneNumbersLoading, incomingPhoneNumbersTrigger } = usePostIncomingPhoneNumbers();
  const { storePhoneNumberSubscriptionTrigger } = usePostStorePhoneNumberSubscription();

  const handleSelectCard = (card_s) => {
    if(!card_s){
      return;
    }

    setCard(card_s);
  }

  const handlePurchasePhoneNumber = async () => {
    
    if(!card && list_payment_methods.length === 0){
      return;
    }

    if(!phoneNumber){
      return;
    }

    let subscription = null;

    try {
      if(!userTwilioSid){
        enqueueSnackbar('Twilio Account Not Found', { variant: 'error' });
        return;
      }
      const response = await subscriptionTrigger({ item: 'price_1Q5Cv0HKxvnTbiHisvEOaPIN', customer: customer.id, payment_method: card || list_payment_methods[0].id });
      subscription = response?.subscription || null;
      const twilioSubscription = await incomingPhoneNumbersTrigger({ userTwilioSid, phoneNumber });
      await storePhoneNumberSubscriptionTrigger({ userId: user.id, phonenumber: twilioSubscription.data.phoneNumber, accountSid:userTwilioSid, subscription_id: subscription.id });
      enqueueSnackbar('Phone Number Purchased', { variant: 'success' });
      navigate('/dashboard/contact_center/phone_numbers');
    } catch (error_h) {
      if(subscription?.id && error_h.code !== 200) {
        await subscriptionCancelTrigger({ subscription_id: subscription.id, customer_id: customer.id, created: subscription.created });
      }

      const { message } = error_h;
      setError(message);
      dialog.onTrue();
      
    }

  }

  const renderActions = (
    <>
      <Grid xs={12} md={12} sx={{ width: '100%' }}>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="inherit" variant="outlined" size="large" sx={{ minWidth: 150 }} onClick={()=> navigate('/dashboard/contact_center/phone_numbers')}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          sx={{ ml: 2, minWidth: 150 }}
          loading={incomingPhoneNumbersLoading || subscriptionLoading}
          onClick={handlePurchasePhoneNumber}
        >
             <Typography variant="subtitle2">Purchase Now</Typography>
        </LoadingButton>
        </Box>
      </Grid>
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={5}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Confirm Purchase
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            You will be charged $1.15 immediately, afterwards you&apos;ll be charged $1.15/month in additon to the usage you incur on the phone number.
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={7}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    Phone Number
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {friendlyName}
                  </Typography>
                  <Typography variant='body2' sx={{ color: 'text.disabled' }}>US</Typography>
                </Paper>
            </Stack>

            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    Monthly Fee
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    ${price}/month
                  </Typography>
                </Paper>
            </Stack>

            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    Capabilities
                  </Typography>
                  <List>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon>
                            <Iconify  
                            icon="lets-icons:check-fill"
                            width={24}
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary='Voice' secondary="Receive incoming calls and make outgoing calls"/>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon>
                            <Iconify  
                            icon="lets-icons:check-fill"
                            width={24}
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary='SMS' secondary="Send and receive text messages"/>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon>
                            <Iconify  
                            icon="lets-icons:check-fill"
                            width={24}
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary='MMS' secondary="Send and receive multi media messages"/>
                    </ListItem>
                  </List>
                </Paper>
            </Stack>

            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    A2P 10DLC Registration Required For US Messaging.
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    To send SMS/MMS Messages To The US With This US Local Number, A Registration Process Is Required. This Process Can Be Performed After The Number Is Purchased 
                    <Typography component="a" variant='body2' href='#' target='_blank'> Learn More About A2P 10DLC Registration</Typography>
                  </Typography>
                </Paper>
            </Stack>
            <Stack spacing={1.5}>
              <PaymentMethods isSelectPlan returnValue onChange={handleSelectCard} />
            </Stack>
          </Stack>
          <CardActions>
            {renderActions}
          </CardActions>
        </Card>
      </Grid>
      <ContactCenterAlert title={`Error ${error?.code || 400}`} LinkTo={error?.moreInfo || '#'} isOpen={dialog.value} onClose={dialog.onFalse}/>
    </>
  );

  return (
    <Grid container spacing={3}>
        {renderDetails}
    </Grid>
  );
}