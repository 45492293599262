import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import Logo from 'src/components/logo';
import { RouterLink } from 'src/routes/components';
import { Typography, useTheme } from '@mui/material';
import { APP_CONFIG } from 'src/config-global';
import { Logo as LogoNew } from 'src/components-latest/logo';

// ----------------------------------------------------------------------

function LogoSalesRush({ single = false, sx, ...other }) {
  const theme = useTheme();

  let default_color = theme.palette.primary.main
  if(other.color !== "") {
    default_color = other.color
  }

  console.log('redirect => ', other)

  return (
    <Link
      component={RouterLink}
      href={APP_CONFIG.app_main_domain}
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      {theme.direction === "rtl" ? (
        <Box sx={{ display: 'flex' }}>
          <Typography 
            variant='h6' 
            sx={{ mt: 3.5, display: 'inline-flex'}}>
              AI
                <Typography variant="h6" sx={{ 
                  // color: default_color 
                }}>.</Typography>SALESRUSH</Typography>
          {/* <Logo sx={{ mt: 3, mb: 1 }} color={other.color} /> */}
          <LogoNew sx={{ mt: 3, ml: 2, mb: 1 }} width={30} height={30} href={other?.redirect} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <LogoNew sx={{ mt: 3, ml: 2, mb: 1 }} width={30} height={30} href={other?.redirect} />
          <Typography variant='h6' sx={{ mt: 3.5, display: 'inline-flex'}}>SALESRUSH<Typography variant="h6" sx={{ color: default_color }}>.</Typography>AI</Typography>
        </Box>
      )}
    </Link>
  );
}

LogoSalesRush.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(LogoSalesRush);
