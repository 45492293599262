import useSWR, { mutate } from 'swr';
import { SALESRUSHAPI } from 'src/config-global';
import { useMemo } from "react";
import { endpoints, postFetcher, fetcher } from "src/utils/axios";
import useSWRMutation from "swr/mutation";

export function useGetSubscription() {
  const updateData = async (user) => {
    
    const URL = `${SALESRUSHAPI.api_url}/user/${user.id}`

    const response = await fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }   

    const data = await response.json();
    
    return data;
  };

  return { 
    mutate: async (user) => {
      const data = await updateData(user);
      mutate(URL, data, false);
      return data;
    }
  };
}

export function usePostRemovePhonenumberSubscription() {
  
    const URL = endpoints.user.phonenumber_subscription.delete;
    
    const { trigger, isMutating, data, error } = useSWRMutation(URL, postFetcher);
  
    const memoizedValue = useMemo(
      () => ({
        removePhoneNumberSubscription: data?.data || [],
        removePhoneNumberSubscriptionMutating: isMutating,
        removePhoneNumberSubscriptionError: error,
        removePhoneNumberSubscriptionTrigger: trigger,
      }),
      [data?.data, error, isMutating, trigger]
    );
  
    return memoizedValue;
  };

export function usePostStorePhoneNumberSubscription() {

    const URL = endpoints.user.phonenumber_subscription.create;
    
    const { trigger, isMutating, data, error } = useSWRMutation(URL, postFetcher);
  
    const memoizedValue = useMemo(
      () => ({
        storePhoneNumberSubscription: data?.data || [],
        storePhoneNumberSubscriptionMutating: isMutating,
        storePhoneNumberSubscriptionError: error,
        storePhoneNumberSubscriptionTrigger: trigger,
      }),
      [data?.data, error, isMutating, trigger]
    );
  
    return memoizedValue;
};

export function useReadAllUsers() {
  const URL = `${SALESRUSHAPI.api_url}/user/list`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data || null,
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
