/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */

'use client';

import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';
// Moment
import moment from 'moment';
import {
  Card,
  Stack,
  Table,
  Button,
  Tooltip,
  Container,
  TableBody,
  IconButton,
  TableContainer
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'src/redux/store';

import { field_types, object_types } from 'src/_mock/_crm-fields';
import { useLandingPagesList, useActivedLandingPages } from 'src/api/landingpages'
import { useReadAllCRMFieldsByUser } from 'src/api/user_fields';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import LandingTableRow from '../landing-table-row';
import FieldTableToolbar from '../landing-table-toolbar'
import LandingTableFiltersResult from '../landing-table-filters-result';

// ----------------------------------------------------------------------

export const LANDING_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const TABLE_HEAD = [
  { id: 'name', label: 'Page Name' },
  { id: 'url_path', label: 'Url Path', width: 180 },
  { id: 'display_on_site', label: 'Display On Site', width: 220 },
  { id: 'save_submission_to', label: 'Save As', width: 180 },
  { id: 'created_by', label: 'Created By' },
  { id: '', width: 88 },
];

const defaultFilters = {
  created: '',
  status: '',
  label: '',
};

// ----------------------------------------------------------------------

export default function LandingListView() {

  const { user } = useSelector((state) => state.user);
  const { data: fields } = useReadAllCRMFieldsByUser(user.organization_id, user.user_level);
  const { enqueueSnackbar } = useSnackbar();

  const table = useTable({ defaultOrderBy: 'name', defaultRowsPerPage: 50 })

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState([]);

  const { landingdata, landingLoading } = useLandingPagesList(user?.id)
  const { mutate: activedLandingPages } = useActivedLandingPages()
  const [resetFilters, setResetFilters] = useState(false)
  const [filters, setFilters] = useState(defaultFilters)
  const [createdOptions, setCreatedOptions] = useState([]);

  const handleResetFilters = useCallback(() => {

    setFilters((prevState) => ({
      ...prevState,
      ...{
        created: '',
        status: '',
        label: '',
      }
    }));

    setResetFilters(true);
  }, [])

  const handleResetResetFilters = useCallback(() => {
    setResetFilters(false)
  }, [])

  useEffect(() => {
    setTableData(landingdata);
  }, [landingdata]);

  useEffect(() => {
    if (fields.length) {
      // setTableData(fields);
      // Set created by options
      const created = fields.map((field) => field.created_by);
      const uniqueCreated = [...new Set(created)];
      // Now we need to set the createdOptions with value and label object
      setCreatedOptions(
        uniqueCreated.map((item) => ({
          value: item,
          label: item,
        })));
    }
  }, [fields]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const denseHeight = table.dense ? 56 : 56 + 20;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setResetFilters(true);
  }, []
  );

  const handleActivateDeactivateRow = async (id, currentStatus, alert = true) => {
    const data = {
      updated_at: moment().format(),
      status: currentStatus === 'active' ? 'inactive' : 'active',
    };
    const { status, message } = await activedLandingPages(data, id);
    if(status === 'success') {
      const index = tableData.findIndex(item => item.id === id);
      const newArr = [...tableData];
      newArr[index].updated_at = data.updated_at;
      newArr[index].status = data.status;
      setTableData(newArr);
      if(alert) {
        enqueueSnackbar(`${currentStatus === 'active' ? 'Deactivation' : 'Activation'} success!`);
      }
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleDeactivateRows = async (ids) => {
    // eslint-disable-next-line
    for (const value of ids) {
      const find = tableData.find(item => item.id === value);
      if(find && find.status === 'active' && find.created_by !== 'Sales Rush') {
        // eslint-disable-next-line
        await handleActivateDeactivateRow(value, 'active', false);
      }
    }
    table.onSelectAllRows(false, dataFiltered.map((row) => row.id));
    enqueueSnackbar('Deactivation success!');
  };

  const handleEditRow = useCallback(
    (id, type) => {
      router.push(paths.dashboard.landing.edit(id, type));
    },
    [router]
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Landing Pages', href: paths.dashboard.landing.root },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.landing.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Landing Page
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 3 },
          }}
        />

        <Stack spacing={5}>
          <Card sx={{ padding: 2, mb: 2 }}>

            <FieldTableToolbar
              filters={filters}
              onFilters={handleFilters}
              resetFilters={resetFilters}
              handleResetFilters={handleResetResetFilters}
              createdOptions={createdOptions}
              objectOptions={object_types}
              fieldOptions={field_types}
              sx={{ pb: 1.5 }}
            />

            {canReset && (
              <LandingTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                //
                onResetFilters={handleResetFilters}
                //
                results={dataFiltered.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={dataFiltered.length}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    dataFiltered.map((row) => row.id)
                  )
                }
                action={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={dataFiltered.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        dataFiltered.map((row) => row.id)
                      )
                    }
                  />

                  <TableBody>
                    {landingLoading ? (
                      [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} avatar={false} />
                      ))
                    ) : (
                      <>
                        {dataFiltered
                          .slice(
                            table.page * table.rowsPerPage,
                            table.page * table.rowsPerPage + table.rowsPerPage
                          )
                          .map((row) => (
                            <LandingTableRow
                              key={row.id}
                              row={row}
                              selected={table.selected.includes(row.id)}
                              onSelectRow={() => table.onSelectRow(row.id)}
                              onEditRow={() => handleEditRow(row.id, row.created_by)}
                              handleActivateDeactivateRow={() => handleActivateDeactivateRow(row.id, row.status)}
                            />
                          ))
                        }
                      </>
                    )}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered.length)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>
        </Stack>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Deactivate'
        content={
          <>
            Are you sure want to Deactivate <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
              handleDeactivateRows(table.selected);
            }}
          >
            Deactivate
          </Button>
        }
      />

    </>
  );
}

// ----------------------------------------------------------------------


function applyFilter({ inputData, comparator, filters }) {

  try {

    const { created, objects, status, label } = filters

    const stabilizedThis = inputData.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })

    inputData = stabilizedThis.map((el) => el[0])


    if (created) {
      inputData = inputData.filter((item) => item.created_by === created);
    }

    if (status !== '') {
      inputData = inputData.filter((item) => status === item.status)
    }

    if (label !== '') {
      inputData = inputData.filter(item => item.page_name.toLowerCase().indexOf(label.toLowerCase()) !== -1)
    }

    return inputData
  }
  catch (err) {
    console.log(err)
    return []
  }
}
