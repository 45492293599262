import PropTypes from 'prop-types';
// @mui
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
// components
import Iconify from 'src/components/iconify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Box, InputAdornment, TextField } from '@mui/material';
 
// ----------------------------------------------------------------------

export default function CallHeader({ onOpenNav, onOpenMail, ...other }) {
  return (
    <Stack spacing={2} direction="row" alignItems="center" sx={{ py: 1 }} {...other}>
      <Stack direction="row" alignItems="center">
      <IconButton onClick={onOpenNav}>
        <MoreVertIcon />
      </IconButton>

        {onOpenMail && (
          <IconButton onClick={onOpenMail}>
            <ReadMoreIcon />
            {/* <Iconify icon="solar:chat-round-dots-bold" /> */}
          </IconButton>
        )}
      </Stack>

      <Box sx={{ flexGrow: 1 }} />

{/*       <TextField
        fullWidth
        size="small"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      /> */}
    </Stack>
  );
}

CallHeader.propTypes = {
  onOpenMail: PropTypes.func,
  onOpenNav: PropTypes.func,
};
