export function getTitleSingular(module) {
  switch (module) {
    case 'contact':
      return 'Contact';
    case 'company':
      return 'Company';
    case 'lead':
      return 'Lead';
    case 'opportunity':
      return 'Opportunity';
    default:
      return 'Contact';
  }
}

export function getTitlePlural(module) {
  switch (module) {
    case 'contact':
      return 'Contacts';
    case 'company':
      return 'Companies';
    case 'lead':
      return 'Leads';
    case 'opportunity':
      return 'Opportunities';
    default:
      return 'Contacts';
  }
}

export function getUpperSingular(module) {
  switch (module) {
    case 'contact':
      return 'CONTACT';
    case 'company':
      return 'COMPANY';
    case 'lead':
      return 'LEAD';
    case 'opportunity':
      return 'OPPORTUNITY';
    default:
      return 'CONTACT';
  }
}

export function getUpperPlural(module) {
  switch (module) {
    case 'contact':
      return 'CONTACTS';
    case 'company':
      return 'COMPANIES';
    case 'lead':
      return 'LEADS';
    case 'opportunity':
      return 'OPPORTUNITIES';
    default:
      return 'CONTACTS';
  }
}

export function getListName(module) {
  switch (module) {
    case 'contact':
      return 'contact_filter_list';
    case 'company':
      return 'company_filter_list';
    case 'lead':
      return 'lead_filter_list';
    case 'opportunity':
      return 'opportunity_filter_list';
    default:
      return 'contact_filter_list';
  }
}

export function getLowerPlurals(module) {
  switch (module) {
    case 'contact':
      return 'contacts';
    case 'lead':
      return 'leads';
    case 'company':
      return 'companies';
    case 'opportunity':
      return 'opportunities';
    default:
      return 'contacts';
  }
}

export function getFieldName(module) {
  switch (module) {
    case 'contact':
      return 'full_name';
    case 'company':
      return 'company_name';
    case 'lead':
      return 'lead_name';
    case 'opportunity':
      return 'opportunity_name';
    default:
      return 'full_name';
  }
}

export function getFieldLabel(module) {
  switch (module) {
    case 'contact':
      return 'Full Name';
    case 'company':
      return 'Company Name';
    case 'lead':
      return 'Lead Name';
    case 'opportunity':
      return 'Opportunity Name';
    default:
      return 'Full Name';
  }
}

export function getLinkName(module) {
  switch (module) {
    case 'contact':
      return 'linked_contacts';
    case 'company':
      return 'linked_companies';
    case 'lead':
      return 'linked_leads';
    case 'opportunity':
      return 'linked_opportunities';
    default:
      return 'linked_contacts';
  }
}
