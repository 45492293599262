import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();


  let primary_main = theme.palette.primary.main;
  let primary_light = theme.palette.primary.light;
  let primary_dark = theme.palette.primary.dark;
  if (other.color === "purple") {
    primary_main = "#7635dc"
    primary_light = "#B985F4"
    primary_dark = "#431A9E"
  }

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 35,
        height: 35,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg width="1024" height="1024" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 14500 1024 1024">
        <defs>
          <linearGradient id="paint0_linear_38_62" x1="826" y1="601" x2="346.666" y2="285.664" gradientUnits="userSpaceOnUse">
          <stop stopColor={primary_light}/>
          <stop offset="1" stopColor={primary_main}/>
          </linearGradient>
          <linearGradient id="paint1_linear_38_62" x1="595" y1="645" x2="369.177" y2="977.633" gradientUnits="userSpaceOnUse">
          <stop stopColor={primary_dark}/>
          <stop offset="0.7367" stopColor={primary_main}/>
          </linearGradient>
          <linearGradient id="paint2_linear_38_62" x1="415.001" y1="397" x2="614.737" y2="63.6744" gradientUnits="userSpaceOnUse">
          <stop stopColor={primary_dark}/>
          <stop offset="0.7367" stopColor={primary_main}/>
          </linearGradient>
        </defs>

        {/* <path d="M0 220C0 98.4974 98.4974 0 220 0H804C925.503 0 1024 98.4974 1024 220V804C1024 925.503 925.503 1024 804 1024H220C98.4974 1024 0 925.503 0 804V220Z" fill="white"/> */}
        <path fillRule="evenodd" clipRule="evenodd" d="M224.136 465.418C164.413 521.433 209.732 612.913 297.206 612.913H501.522L347.674 948.547L798.864 559.231C858.587 503.216 813.268 411.735 725.794 411.735H521.381L675.722 76.002L224.136 465.418Z" fill="url(#paint0_linear_38_62)"/>
        <path d="M611.358 721.088L347.673 948.551L501.661 612.816L557.845 612.816C612.866 612.816 659.111 676.506 611.358 721.088Z" fill="url(#paint1_linear_38_62)"/>
        <path d="M410.877 304.391L675.723 76L522.082 411.735H465.898C410.877 411.735 363.123 348.973 410.877 304.391Z" fill="url(#paint2_linear_38_62)"/>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/dashboard" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;