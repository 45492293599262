import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

export default function RHFTextField({ name, helperText, type, InputAdormet, onChangeInput, disabled = false, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          disabled={disabled}
          fullWidth
          type={type}
          // value={type === 'number' && field.value === 0 ? '' : field.value}
          value={field.value}
          InputProps={InputAdormet}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(event.target.value !== '' ? Number(event.target.value) : '');
            } else if (typeof onChangeInput === "function") {
              field.onChange(onChangeInput(event.target.value))
            } else {

              if (other?.otp) {
                if (event.target.value === '') field.onChange(event.target.value)
                else if (/[0-9]{7}/g.test(event.target.value)) return
                else if (other?.digits && !/[0-9]/.test(event.target.value)) return
              }



              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  InputAdormet: PropTypes.any,
  onChangeInput: PropTypes.func,
  disabled: PropTypes.bool,
};
