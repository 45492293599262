// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  NEWHOME: '/new-home',
  REFINANCE: '/refinance',
  LISTINGS: '/listings',
  FORM: '/form',
};

// ----------------------------------------------------------------------

export const paths = {
  home: '/',
  newHome: `${ROOTS.NEWHOME}`,
  refinance: `${ROOTS.REFINANCE}`,
  listings: {
    homes: `${ROOTS.LISTINGS}`,
  },
  form: {
    detail: (slug) => `${ROOTS.FORM}/${slug}`,
  },
  //----------------------------
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (slug) => `/post/${slug}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    verify: `${ROOTS.AUTH}/verify`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    contact_center: {
      root: `${ROOTS.DASHBOARD}/contact_center`,
      calls: `${ROOTS.DASHBOARD}/contact_center/calls`,
      messages: `${ROOTS.DASHBOARD}/contact_center/messages`,
      phone_numbers: `${ROOTS.DASHBOARD}/contact_center/phone_numbers`,
      usage: `${ROOTS.DASHBOARD}/contact_center/usage`,
      a2p: `${ROOTS.DASHBOARD}/contact_center/a2p`,
    },
    reactivate: `${ROOTS.DASHBOARD}/reactivate`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    crm: {
      root: `${ROOTS.DASHBOARD}/crm`,
      contact: {
        root: `${ROOTS.DASHBOARD}/crm/contact`,
        new: `${ROOTS.DASHBOARD}/crm/contact/new`,
        list: `${ROOTS.DASHBOARD}/crm/contact/list`,
        account: `${ROOTS.DASHBOARD}/crm/contact/account`,
        edit: (id) => `${ROOTS.DASHBOARD}/crm/contact/${id}/edit`,
      },
      company: {
        root: `${ROOTS.DASHBOARD}/crm/company`,
        new: `${ROOTS.DASHBOARD}/crm/company/new`,
        list: `${ROOTS.DASHBOARD}/crm/company/list`,
        account: `${ROOTS.DASHBOARD}/crm/company/account`,
      },
      lead: {
        root: `${ROOTS.DASHBOARD}/crm/lead`,
        new: `${ROOTS.DASHBOARD}/crm/lead/new`,
        list: `${ROOTS.DASHBOARD}/crm/lead/list`,
        account: `${ROOTS.DASHBOARD}/crm/lead/account`,
      },
      opportunity: {
        root: `${ROOTS.DASHBOARD}/crm/opportunity`,
        new: `${ROOTS.DASHBOARD}/crm/opportunity/new`,
        list: `${ROOTS.DASHBOARD}/crm/opportunity/list`,
        account: `${ROOTS.DASHBOARD}/crm/opportunity/account`,
      },
      settings: {
        root: `${ROOTS.DASHBOARD}/crm/settings`,
        list: `${ROOTS.DASHBOARD}/crm/settings/list`,
        new: `${ROOTS.DASHBOARD}/crm/settings/new`,
        edit: (id, owner) => `${ROOTS.DASHBOARD}/crm/settings/${id}/${owner}/edit/`,
      },
      pipelines: {
        root: `${ROOTS.DASHBOARD}/crm/pipelines`,
        list: `${ROOTS.DASHBOARD}/crm/pipelines/list`,
        new: `${ROOTS.DASHBOARD}/crm/pipelines/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/crm/pipelines/${id}/edit`,
      },
      // settings2: {
      //   root: `${ROOTS.DASHBOARD}/crm/settings2`,
      //   list: `${ROOTS.DASHBOARD}/crm/settings2/list`,
      //   new: `${ROOTS.DASHBOARD}/crm/settings2/new`,
      //   edit: (id, owner) => `${ROOTS.DASHBOARD}/crm/settings2/${id}/${owner}/edit/`,
      // }
    },
    landing: {
      root: `${ROOTS.DASHBOARD}/landing`,
      new: `${ROOTS.DASHBOARD}/landing/new`,
      list: `${ROOTS.DASHBOARD}/landing/list`,
      view: (id) => `${ROOTS.DASHBOARD}/landing/${id}/edit`,
      edit: (id) => `${ROOTS.DASHBOARD}/landing/${id}/edit`,
    },
    user: {
      profile: `${ROOTS.DASHBOARD}/user/profile`,
    },
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      users: {
        root: `${ROOTS.DASHBOARD}/users/users`,
        new: `${ROOTS.DASHBOARD}/users/users/new`,
        list: `${ROOTS.DASHBOARD}/users/users/list`,
        edit: (id) => `${ROOTS.DASHBOARD}/users/users/edit/${id}`,
      },
      teams: {
        root: `${ROOTS.DASHBOARD}/users/teams`,
        new: `${ROOTS.DASHBOARD}/users/teams/new`,
        list: `${ROOTS.DASHBOARD}/users/teams/list`,
        edit: (id) => `${ROOTS.DASHBOARD}/users/teams/edit/${id}`,
      },
      permissions: {
        root: `${ROOTS.DASHBOARD}/users/permissions`,
        new: `${ROOTS.DASHBOARD}/users/permissions/new`,
        list: `${ROOTS.DASHBOARD}/users/permissions/list`,
        edit: (id) => `${ROOTS.DASHBOARD}/users/permissions/${id}/edit`,
      }
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    review: {
      root: `${ROOTS.DASHBOARD}/review`,
      new: `${ROOTS.DASHBOARD}/review/new`,
      details: (id) => `${ROOTS.DASHBOARD}/review/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/review/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/review/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/review/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
