import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { Box, Button, IconButton, List, ListItemButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { endpoints, fetcher } from 'src/utils/axios';
import { useSelector } from 'src/redux/store';
import EmptyContent from 'src/components/empty-content';
import { CallItemSkeleton } from './call-log/call-skeleton';
import { useTwilio } from './twilio-provider';



export default function PhoneNumberRecents({ Section, callActive, setActiveSection, makeCall, setPhoneNumber, setSelectedCallInfo, handleAddParticipant}) {
    const user = useSelector((state) => state.user);
    const { userTwilioPhone } = useTwilio();
    const userTwilioSid = user.user.twilio_sid;
    const userId = user.user.id;
    const [recentsData, setRecentsData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const params = useMemo(() => ({
        userTwilioSid,
        userTwilioPhone,
        selectedLabelId: "recents",
        userId,
    }), [userTwilioSid, userTwilioPhone, userId]);

    useEffect(() => {
        // Limpia los datos al cambiar la sección
        setRecentsData([]);
        setIsLoading(true);
    }, [Section]);

    useEffect(() => {
        const fetchData = async () => {
            const URL = Section !== "Voicemail"
                ? endpoints.twilio.calls.log
                : endpoints.twilio.calls.voicemail;
            try {
                const data = await fetcher([URL, { params }]);
                if (data.status === "success") {
                    // setIsLoading(false);
                    setRecentsData(data.data);
                }
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [Section, params]);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {/* Encabezado */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 2
                    }}
                >
                    <Typography variant="h6" sx={{ marginBottom: 0 }}>
                        {Section}
                    </Typography>
                    {callActive && (<Button
                        onClick={() => {
                            handleAddParticipant()
                        }}
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        Back to call
                    </Button>)}
                </Box>
                <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 2 }}>
                    {[...Array(10)].map((_, index) => (
                        <CallItemSkeleton key={index} />
                    ))}
                </Box>
            </Box>
        )
    }

    return recentsData.length <= 0 ? (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: { xs: '75vh', sm: '55vh' },
            }}
        >
            {/* Encabezado */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 2
                }}
            >
                <Typography variant="h6" sx={{ marginBottom: 0 }}>
                    {Section}
                </Typography>
                {callActive && (<Button
                    onClick={() => {
                        // const activeSection = selectedCallInfo?.recordingurl ? 2 : 0
                        handleAddParticipant()
                    }}
                    sx={{
                        fontWeight: "bold",
                    }}
                >
                    Back to call
                </Button>)}
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingX: 2, marginBottom: 2 }}>
                <EmptyContent
                    title={`Nothing in ${Section}`}
                    imgUrl="/assets/icons/empty/ic_folder_empty.svg"
                    sx={{
                        borderRadius: 1.5,
                        maxWidth: { md: 320 },
                    }}
                />
            </Box>
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: { xs: '75vh', sm: '55vh' },
            }}
        >
            {/* Encabezado */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 2
                }}
            >
                <Typography variant="h6" sx={{ marginBottom: 0 }}>
                    {Section}
                </Typography>
                {callActive && (<Button
                    onClick={() => {
                        handleAddParticipant()
                    }}
                    sx={{
                        fontWeight: "bold",
                    }}
                >
                    Back to call
                </Button>)}
            </Box>

            {/* Lista de llamadas */}
            <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingX: 2, marginBottom: 2 }}>
                <List>
                    {recentsData.map((item, index) => (
                        <ListItemButton
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 0',
                            }}
                            onClick={async () => {
                                if (Section !== "Voicemail") {
                                    const phone = item.to.includes("client") ? item.from : item.to;
                                    if (!callActive) {
                                        console.log("Recents Llamando a: ", phone);
                                        setPhoneNumber(phone)
                                        await makeCall(phone)
                                        setActiveSection(1)
                                    } else {
                                        console.log("callActive: ", callActive);
                                        await makeCall(phone)
                                    }

                                } else {
                                    await setSelectedCallInfo(item)
                                    setActiveSection(3)
                                }
                            }}
                        >
                            <Box>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'normal' }} color={item?.to && typeof item.to === "string" && item.to.includes("client") ? "red" : "inherit"}
                                >
                                    {(() => {
                                        if (item.direction !== 'inbound') {
                                            return item.to.includes("client") ? item.from : item.to;

                                        }
                                        return item.from;

                                    })()}
                                </Typography>
                                <Typography variant="body2" color={item?.to && typeof item.to === "string" && item.to.includes("client") ? "red" : "inherit"}
                                >
                                    {(() => {
                                        if (item.direction === 'inbound') {
                                            return 'inbound';
                                        }
                                        if (item.to.includes("client")) {
                                            return 'missed';
                                        }
                                        return 'outbound';
                                    })()}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body2" color="textSecondary" sx={{ marginRight: 0 }}>
                                    {formatDistanceToNowStrict(new Date(item.dateCreated), {
                                        addSuffix: false,
                                    })}
                                </Typography>
                                {Section !== "Voicemail" && (
                                    <IconButton
                                        size="small"
                                        onClick={async (event) => {
                                            event.stopPropagation(); // Detiene la propagación del clic al ListItemButton
                                            await setSelectedCallInfo(item)
                                            setActiveSection(3)
                                        }}
                                    >
                                        <InfoIcon color="primary" />
                                    </IconButton>
                                )}
                            </Box>
                        </ListItemButton>
                    ))}
                </List>
            </Box>
        </Box>
    )
};

PhoneNumberRecents.propTypes = {
    Section: PropTypes.string,
    setActiveSection: PropTypes.func,
    makeCall: PropTypes.func,
    setPhoneNumber: PropTypes.func,
    callActive: PropTypes.bool,
    setSelectedCallInfo: PropTypes.func,
    handleAddParticipant: PropTypes.func
};