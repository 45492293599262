


import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './App';
import { initLogRocket } from './utils/logrocket';
import { initSentry } from './utils/sentry';


Sentry.init({
  dsn: "https://da81dcc2f7b3bfd3e803a208a704567b@o4508609399685120.ingest.us.sentry.io/4508609424195584",
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration({
      beforeStartSpan: function (context) {
        return {
          ...context,
          name: window.location.pathname,
        };
      },

    }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    "127.0.0.1",
    /^https:\/\/us-central1-homepath-3c9b6\.cloudfunctions\.net\/app/,
    /^https:\/\/us-central1-sales-rush-development\.cloudfunctions\.net\/app/,
  ],
  profilesSampleRate: 1.0,
});

// ----------------------------------------------------------------------

initLogRocket();
initSentry();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
