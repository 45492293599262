import * as Sentry from '@sentry/react';

const { NODE_ENV } = process.env;

export const initSentry = () => {
  if (NODE_ENV && NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://da81dcc2f7b3bfd3e803a208a704567b@o4508609399685120.ingest.us.sentry.io/4508609424195584',
      integrations: [],
    });
  }
};
