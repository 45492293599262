import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { clientTypesense } from 'src/utils/typesense';

const useLeadSearchStore = create(
  persist(
    (set) => ({
      rows: 0, // Número de filas encontradas
      searchParams: {}, // Parámetros de búsqueda iniciales
      searchResults: [], // Resultados de búsqueda iniciales
      isLoading: false, // Estado de carga
      // Acción para actualizar los parámetros de búsqueda
      setSearchParams: (params) => set({ searchParams: params }),
      // Acción para realizar la búsqueda
      updateSearch: async (params) => {
        set({ isLoading: true });
        try {
          const results = await clientTypesense.collections('crm_records').documents().search(params);
          set({ isLoading: false, searchResults: results.hits, rows: results.found });
        } catch (error) {
          console.error('Error during search:', error);
          set({ isLoading: false });
        }
      },
      // Acción para restaurar el estado de búsqueda
      restoreSearch: () => set({ searchResults: [], searchParams: {} }),
      // Acción para actualizar los resultados de búsqueda manualmente
      setSearchResults: (results) => set({ searchResults: results }),
      // Acción para limpiar el estado y el almacenamiento persistente
      clearState: () => {
        set({ rows: 0, searchParams: {}, searchResults: [], isLoading: false });
        localStorage.removeItem('lead-search-store');
      },
    }),
    {
      name: 'lead-search-store', // Nombre de la clave en el local storage
      getStorage: () => localStorage, // Usar local storage
      onRehydrateStorage: () => (state) => {
        // Manejar cualquier posible inconsistencia en los datos restaurados
        if (!state) {
          return {
            rows: 0,
            searchParams: {},
            searchResults: [],
            isLoading: false,
          };
        }
        return state;
      },
    }
  )
);

export default useLeadSearchStore;