import { memo } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
import { Link } from 'react-router-dom';

const PhoneNumberActiveTableRow = memo(({ row }) => {

  const { phone, friendly_name, a2p, sid, owner } = row;

  return (
    <TableRow hover>
      <TableCell>
        <Typography 
        color="inherit"
        variant="subtitle2"
        noWrap
        sx={{ textTransform: 'capitalize', color: '#00B8D9', textDecoration: 'underline', cursor: 'pointer' }}
        component={Link} 
        to={`/dashboard/contact_center/phone_numbers/${sid}?owner=${owner}`} 
        >{phone}</Typography>

        {/* <Link to={`/dashboard/contact_center/phone_numbers/${sid}?owner=${owner}`} style={{ textDecoration: 'none', color: 'black' }}>{phone}</Link> */}
      </TableCell>
      <TableCell sx={{ textAlign: 'left' }}>{friendly_name}</TableCell>
      <TableCell>Local</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Iconify
          icon="lets-icons:check-fill"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        /></TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Iconify
          icon="lets-icons:check-fill"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Iconify
          icon="lets-icons:check-fill"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        {
          a2p && <Iconify
            icon="lets-icons:check-fill"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
          || <Iconify
            icon="jam:alert-f"
            sx={{
              color: (theme) => theme.palette.warning.main,
            }}
          />
        }
      </TableCell>
    </TableRow>)
})

export default PhoneNumberActiveTableRow;

PhoneNumberActiveTableRow.propTypes = {
  row: PropTypes.object,
};
